<template>
  <div class="card">
    <div class="card-body">
      <div class="row success">
        <div class="col-xl-8">
          <h5 style="color: blue; margin-bottom: 30px">
            <span style="margin-right: 30px"> {{ $t("bulletin.notes") }}</span>
            <a-tooltip
              placement="right"
              v-if="selectedClasse && selectedTrimester"
            >
              <template slot="title">
                {{
                  isValidatedBulletin()
                    ? $t("bulletin.hideBulletin")
                    : $t("bulletin.validateBulletin")
                }}
              </template>
              <span>
                <a-button
                  :type="isValidatedBulletin() ? 'primary' : 'danger'"
                  @click="confirmeBulletinValidation()"
                >
                  {{
                    isValidatedBulletin()
                      ? $t("bulletin.cancelValidation")
                      : $t("bulletin.validatedClassroom")
                  }}
                </a-button>
              </span>
            </a-tooltip>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8">
          <label>{{ $t("bulletin.listeClasses") }}</label>
          <a-select
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 150px; margin-left: 15px"
            @change="
              (val) => {
                handleClassChange('classe', val);
              }
            "
          >
            <a-select-option value="all"> --- </a-select-option>
            <a-select-option
              v-for="classe in classes"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("scolarite.listeModules")
          }}</label>
          <a-select
            :placeholder="$t('scolarite.listeModules')"
            style="width: 180px; margin-left: 15px"
            :loading="moduleLoading"
            v-model="selectedModule"
            @change="
              (val) => {
                this.selectedModule = val;

                this.listModules.forEach((module) => {
                  if (module._id == val) this.selectedModuleName = module.name;
                });

                handleModuleChange(val);
              }
            "
          >
            <a-select-option
              v-for="modul in listModules"
              :key="modul._id"
              :value="modul._id"
            >
              {{ modul.name }}
            </a-select-option>
          </a-select>

          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 50px; margin-left: 15px"
            @change="
              (val) => {
                this.selectedTrimester = val;
                handleClassChange('classe', this.selectedClasse);
                updateMarks();
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>

          <a-dropdown style="margin-left: 15px" v-if="selectedClasse">
            <template #overlay>
              <a-menu
                @click="
                  (val) => {
                    if (val.key == '1') printTable();
                    else if (val.key == '2') printTable(true);
                  }
                "
              >
                <a-menu-item key="1">{{
                  $t("bulletin.imprimerFichModuleVide")
                }}</a-menu-item>
                <a-menu-item key="2">{{
                  $t("bulletin.imprimerFichModule")
                }}</a-menu-item>
              </a-menu>
            </template>
            <a-button
              type="primary"
              :loading="printLoading"
              :disabled="printLoading"
            >
              <a-icon v-if="!printLoading" type="file-pdf" />
              {{ $t("action.imprimer") }}
            </a-button>
          </a-dropdown>

          <br />
          <br />
          <div class="card" v-if="selectedModule && selectedClasse">
            <a-table
              :columns="columns"
              :data-source="activeData"
              :loading="tableLoading"
            >
              <template
                v-for="subject in activeModule.subjects"
                :slot="subject._id"
                slot-scope="text, record"
              >
                <div
                  :key="subject._id"
                  :class="
                    getMarkValue(subject._id, record._id) < 0 ||
                    getMarkValue(subject._id, record._id) > 20
                      ? 'danger'
                      : ''
                  "
                >
                  <a-input-number
                    :value="getMarkValue(subject._id, record._id)"
                    style="height: 35px; width: 70px; text-align: center"
                    @change="
                      (val) => {
                        handleMarkChange(val, subject._id, record._id);
                      }
                    "
                  ></a-input-number>
                  <a-popconfirm
                    :title="$t('all.sureToDelete')"
                    @confirm="() => deleteMark(subject._id, record._id)"
                  >
                    <a-button
                      tabindex="-1"
                      type="dashed"
                      shape="circle"
                      class="ml-2"
                      icon="delete"
                    ></a-button>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </div>
          <div
            class="row"
            v-if="selectedClasse && selectedModule && activeData.length !== 0"
          >
            <div class="col-xl-7">{{ $t("bulletin.toutMatieres") }}</div>
            <div class="col-xl-3">
              <a-button
                :loading="saveLoading"
                style="
                  margin-right: 5px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  background: #8bc34a !important;
                  border-color: #8bc34a !important;
                "
                type="primary"
                @click="saveAll()"
              >
                {{ $t("bulletin.enregistrerTout") }}<a-icon type="check" />
              </a-button>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div
            class="card"
            style="padding: 15px"
            v-if="classroomMarksStats && classroomMarksStats.classrooms"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
                  {{ $t("bulletin.classesActives") }}
                </h2>
                <p
                  class="font-size-15 mb-3"
                  v-if="classroomMarksStats.classrooms.length !== 0"
                >
                  {{ $t("bulletin.nombreClassesNonNote") }}
                </p>
                <p class="font-size-15 mb-3" v-else>
                  {{ $t("bulletin.tousClassesSontNotes") }}
                </p>
              </div>
              <div
                v-if="classroomMarksStats.classrooms.length !== 0"
                class="text-success font-weight-bold font-size-24"
              >
                {{ classroomMarksStats.classrooms.length }}
                {{ $t("bulletin.classes") }}
              </div>

              <div class="text-success font-weight-bold font-size-24" v-else>
                <a-icon type="check" />
              </div>
            </div>

            <div
              class="d-flex text-gray-5 justify-content-right font-size-14"
              v-if="classroomMarksStats.classrooms.length !== 0"
            >
              <a-button @click="visibleClassnameList = true">{{
                $t("action.afficher")
              }}</a-button>
            </div>
          </div>

          <div
            class="card"
            style="padding: 15px"
            v-if="selectedClasse && classroomMarksStats.subjects"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="pr-3">
                <h2 class="font-size-18 font-weight-bold mb-1 text-dark">
                  {{ selectedClassName }}
                </h2>
                <p
                  class="font-size-15 mb-3"
                  v-if="
                    classroomMarksStats.subjects[selectedClasse] &&
                    classroomMarksStats.subjects[selectedClasse].length !== 0
                  "
                >
                  {{ $t("bulletin.nombreMatieresNonNote") }}
                </p>
                <p class="font-size-15 mb-3" v-else>
                  {{ $t("bulletin.tousMatieresSontNotes") }}
                </p>
              </div>
              <div
                class="text-success font-weight-bold font-size-24"
                v-if="
                  classroomMarksStats.subjects[selectedClasse] &&
                  classroomMarksStats.subjects[selectedClasse].length !== 0
                "
              >
                {{ classroomMarksStats.subjects[selectedClasse].length }}
                matières
              </div>
              <div class="text-success font-weight-bold font-size-24" v-else>
                <a-icon type="check" />
              </div>
            </div>

            <div
              class="d-flex text-gray-5 justify-content-right font-size-16"
              v-if="classroomMarksStats.subjects[selectedClasse]"
            >
              <a-button @click="visibleSubjectList = true">{{
                $t("action.afficher")
              }}</a-button>
            </div>
          </div>

          <certBar
            v-if="selectedClasse"
            :data="bulletins"
            :certifParams="certifParams"
          />

          <a-divider
            v-if="selectedClasse && selectedModule && activeData.length !== 0"
            ><span class="font-size-18">{{
              selectedModuleName
            }}</span></a-divider
          >

          <div
            class="card"
            v-if="selectedModule && selectedClasse && activeData.length !== 0"
          >
            <moduleChart
              :bulletins="bulletins"
              :moduleName="selectedModuleName"
              :classroom="selectedClasse"
              :certifParams="certifParams"
            />
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visibleClassnameList"
      :title="$t('bulletin.listeClassesNonNote')"
      :width="500"
      :height="700"
      :key="'classnameList'"
    >
      <div :style="{ overflowY: 'scroll' }">
        <div
          v-for="classname in classroomMarksStats.classrooms"
          :key="classname"
        >
          {{ classname }}
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="visibleSubjectList"
      :title="$t('bulletin.listeMatiereNonNote')"
      :width="500"
      :height="700"
      :key="'subjectsListNotMarked'"
    >
      <div
        :style="{ overflowY: 'scroll' }"
        v-if="selectedClasse && classroomMarksStats.subjects"
      >
        <div
          v-for="name in classroomMarksStats.subjects[selectedClasse]"
          :key="name"
        >
          {{ name }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import marks from "./components/marks";
import moduleChart from "./components/moduleChart";
import certBar from "./components/certBar.vue";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";

export default {
  computed: mapState(["settings", "user"]),
  components: { marks: marks, moduleChart, certBar },
  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    window.addEventListener("beforeunload", this.confirmLeaveReload);

    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletin: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));

    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    console.log("this.schoolType ", this.schoolType);
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    apiClient
      .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/normal")
      .then(({ data }) => {
        this.classroomMarksStats = data;
      });
  },
  data() {
    return {
      updatedChanges: true,
      printLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      schoolType: null,
      filtredTable: [],
      classroomMarksStats: {},
      selectedModule: this.$t("scolarite.listeModules"),
      selectedModuleName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      listModules: [],
      selectedLevel: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    moment,
    confirmLeaveReload(event) {
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },
    confirmeBulletinValidation() {
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      if (this.selectedClasse) {
        const bulletin = this.classes.find(
          (item) => item._id === this.selectedClasse
        ).bulletin || { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      const isValidated = this.isValidatedBulletin();
      const bulletin = this.classes.find(
        (item) => item._id === this.selectedClasse
      ).bulletin || { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            bulletin: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: "pedagogic",
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse) item.bulletin = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },

    async getModules(level) {
      await apiClient
        .post("/module/filter", {
          query: {
            status: "active",
            level: level,
            trimester: this.selectedTrimester,
          },
        })
        .then((res) => {
          this.listModules = res.data;
          if (res.data.length == 0)
            this.$message.warning(this.$t("error.aucModule"));
          console.log("this.listModules : ", this.listModules);
          this.moduleLoading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.moduleLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;
      const toAddData = [];

      let thereError = false;
      for (const mark of this.markData) {
        if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
          thereError = true;
          break;
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.invalidMarks"));
        this.saveLoading = false;
        return;
      }

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) => m.student == mark.student && m.subject == mark.subject
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/mark/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          toAddData.push({
            subject: mark.subject,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            type: "one",
          });
        }
      }

      await apiClient
        .put("/mark/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            m.subject == originalMark.subject
        );
        if (!mark)
          await apiClient
            .delete("/mark/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();

      this.saveLoading = false;
      this.updatedChanges = true;
      this.$message.success(this.$t("success.noteToutesEnregistre"));
    },

    deleteMark(subjectId, studentId) {
      this.markData = this.markData.filter(
        (m) => !(m.student == studentId && m.subject == subjectId)
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, studentId) {
      const mark = this.markData.find(
        (m) => m.student == studentId && m.subject == subjectId
      );
      if (mark) mark.mark = Number(newMark);
      else
        this.markData.push({
          student: studentId,
          subject: subjectId,
          mark: Number(newMark),
        });

      this.updatedChanges = false;
    },

    getMarkValue(subjectId, studentId) {
      const mark = this.markData.find(
        (m) => m.student == studentId && m.subject == subjectId
      );
      if (mark) return mark.mark;
      return null;
    },

    async handleModuleChange(val) {
      this.columns = [
        {
          title: "#",
          dataIndex: "num",
          key: "num",
          scopedSlots: {
            customRender: "num",
          },
        },
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
      ];

      const module = this.listModules.find((m) => m._id == val);
      this.activeModule = module;
      module.subjects.map((s) => {
        this.columns.push({
          title: s.name,
          dataIndex: s._id,
          scopedSlots: { customRender: s._id },
        });
      });

      this.updateMarks();
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/mark/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            const _m = this.markData.find(
              (m) => m.student == mark.student && m.subject == mark.subject
            );
            if (!_m)
              this.markData.push({
                student: mark.student,
                subject: mark.subject,
                mark: mark.mark,
              });
          });
        })
        .finally(() => (this.tableLoading = false));
    },

    async handleClassChange(on, value) {
      this.markRefs = [];
      this.selectedModule = "";
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      console.log("this.selectedLevel :", this.selectedLevel);
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                firstNameLower: { $toLower: "$firstName" },

                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          console.log(data);
          this.rowData = [];
          let counter = 1;
          data.map((elem) => {
            try {
              this.rowData.push({
                num: counter++,
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.filtredTable = this.activeData;
        });
      //get modules
      this.getModules(this.selectedLevel);
      this.updateCertChartData();
      this.updateMarks();
    },

    async updateCertChartData() {
      apiClient
        .get(
          "/bulletin/all/" + this.selectedClasse + "/" + this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletins = data;
        });

      apiClient
        .get("/certification/peda")
        .then((res) => {
          this.certifParams = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async printTable(withGrades = false) {
      try {
        this.$gtag.event(withGrades ? "Imp fich remplie" : "Imp fich vide", {
          event_category: "Impression PDF",
          event_label: "Bulletin peda-NotesModules section",
          value: 1,
        });

        this.printLoading = true;
        var doc = new JsPDF("p");
        const pdf_width = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        let schoolDetails = this.user.building.find(
          (el) => el.dbName == this.settings.activeBuilding
        );
        let { start, end } = this.settings.schoolarYears.find(
          (el) => el._id == this.settings.activeSchoolarYear
        );

        start = new Date(start).getFullYear();
        end = new Date(end).getFullYear();

        //console.log(this.schoolarYearName);
        let schoolarYearName = end + " / " + start;

        let className = this.classes.find(
          (el) => el._id == this.selectedClasse
        ).name;
        let date = new Date();
        doc.setFontSize(10);
        doc.setFont("Amiri", "Bold");
        doc.setTextColor(140, 140, 140);
        let formattedDate = moment(date)
          .locale("ar-tn")
          .format("dddd، D MMMM، YYYY");
        doc.text(15, 15, formattedDate);
        //fix issue related to arabic name not defined
        let schoolName = schoolDetails.ArabicName
          ? schoolDetails.ArabicName
          : schoolDetails.name;
        let width1 = doc.getTextWidth(`: المدرسة `);
        doc.text(pdf_width - width1 - 15, 15, ` : المدرسة `);
        let width2 = doc.getTextWidth(schoolName);
        doc.text(pdf_width - width1 - width2 - 15, 15, schoolName);

        width1 = doc.getTextWidth(`${schoolarYearName} : السنة الدراسية`);
        doc.text(
          pdf_width - width1 - 15,
          20,
          `${schoolarYearName} : السنة الدراسية`
        );

        width1 = doc.getTextWidth(`: القسم `);
        doc.text(pdf_width - width1 - 15, 25, ` : القسم `);
        width2 = doc.getTextWidth(className);

        doc.text(pdf_width - width1 - width2 - 15, 25, className);

        let first = true;
        this.listModules.forEach((module) => {
          if (!first) doc.addPage();
          else first = false;

          doc.setFontSize(10);
          doc.setFont("Amiri", "Bold");
          doc.setTextColor(140, 140, 140);

          doc.setFontSize(14);
          if (/[a-zA-Z]/.test(module.name)) {
            doc.setFont("helvetica", "bold");
          } else {
            doc.setFont("Noto Sans Arabic", "Bold");
          }
          let width = doc.getTextWidth(module.name);
          doc.text(module.name, pdf_width / 2 - width / 2, 25);

          const array = [];

          this.activeData.forEach((student) => {
            const data = [];
            module.subjects.forEach((subject) => {
              const mark = this.originalMarkData.find(
                (mark) =>
                  mark.subject == subject._id && mark.student == student._id
              );
              if (mark && withGrades) data.push(mark.mark);
              else data.push("");
            });
            data.push(student.fullName);
            data.push(student.num);
            array.push(data);
          });

          const columnStyles = {};
          for (let i = 0; i < module.subjects.length; i++) {
            columnStyles[i] = { cellWidth: 140 / module.subjects.length };
          }

          doc.autoTable({
            theme: "grid",
            styles: {
              fontSize: 9,
              font: "Amiri",
            },
            headStyles: {
              fontSize: 9,
              font: "Noto Sans Arabic",
              fontStyle: "Bold",
              lineColor: [204, 204, 204],
              lineWidth: 0.1,
            },
            bodyStyles: {
              fontSize: 9,
            },
            cellPadding: 0,
            body: array,
            head: [
              [
                ...module.subjects.map((s) => ({
                  content: s.name,
                  halign: "center",
                })),
                {
                  content: "الإسم و اللقب",
                  halign: "center",
                  valign: "middle",
                  font: "helvetica",
                },
                "#",
              ],
            ],
            margin: { top: 35 },
            columnStyles: columnStyles,
            didParseCell: function (data) {
              if (data.section == "head") {
                if (/[a-zA-Z]/.test(data.cell.text[0])) {
                  data.cell.styles.font = "helvetica";
                  data.cell.styles.fontStyle = "bold";
                } else if (data.cell.text[0] == "#") {
                  data.cell.styles.font = "Amiri";
                  data.cell.styles.fontStyle = "Bold";
                  data.cell.styles.fontSize = 12;
                } else {
                  data.cell.styles.font = "Noto Sans Arabic";
                  data.cell.styles.fontStyle = "Bold";
                }
              }
              data.cell.styles.halign = "center";
              data.cell.styles.valign = "middle";
            },
          });
        });

        const pages = doc.internal.getNumberOfPages();

        doc.setFontSize(8);
        doc.setFont("Amiri", "Bold");

        for (let j = 1; j < pages + 1; j++) {
          let horizontalPos = pdf_width / 2;
          let verticalPos = pageHeight - 10;
          doc.setPage(j);
          doc.text(` ${pages} / ${j} صفحة`, horizontalPos, verticalPos, {
            align: "center",
          });
        }

        this.printLoading = false;

        if (withGrades) doc.save(className + " - Fiche remplie.pdf");
        else doc.save(className + " - Fiche vide.pdf");
      } catch (error) {
        console.error(error);
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.danger .ant-input-number {
  border-color: #f5222e !important;
}

.danger .ant-input-number-focused {
  border-color: #f5222e !important;
}
</style>
