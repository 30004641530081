<template>
  <div>
    <!--begin::Chart-->
    <apexchart class="statistics-widget-3-chart card-rounded-bottom" :options="options" :series="series" :height="250"
      type="pie"></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>

export default {
  props: ["bulletins", "classroom", "certifParams", "moduleName"],
  data() {
    return {
      options: {},
      series: [0, 0, 0, 0],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    }
  },

  watch: {
    bulletins: function (value) {
      this.updateChart()
    },

    classroom: function (value) {
      this.updateChart()
    },

    moduleName: function (value) {
      this.updateChart()
    },
  },

  created() {
    this.updateChart()
  },

  methods: {
    getCertificationNameByMoyenne(moyenne) {
      let min = 20
      let c
      for (const cert of this.certifParams) {
        if (Number(cert.min) < min) min = Number(cert.min)
        if (Number(moyenne) >= Number(cert.min) && Number(moyenne) <= Number(cert.max)) c = cert
      }
      if (c) {
        return c
      }
      return { max: min, min: 0 }
    },
    async updateChart() {
      const marksSerie = []
      const namesSerie = []
      const nbCerts = {}

      this.bulletins.students.forEach((student) => {
        student.modules.forEach((m) => {
          if (m.name === this.moduleName && (m.moyenne !== "" || m.moyenne !== "--")) {
            const cert = this.getCertificationNameByMoyenne(Number(m.moyenne))
            const name = `${cert.min} - ${cert.max}`
            if (nbCerts[name]) { nbCerts[name]++ } else { nbCerts[name] = 1 }
          }
        })
      })

      for (const name in nbCerts) {
        namesSerie.push(name)
        marksSerie.push(nbCerts[name])
      }

      this.updateOptions(namesSerie)
      this.updateSeries(marksSerie)
    },

    updateSeries(marksSerie) {
      this.series = marksSerie
    },
    updateOptions(namesSerie) {
      this.options = {
        chart: {
          height: 250,
          type: "pie",
        },
        labels: namesSerie,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      }
    },
  },
}
</script>
